import { L as LIST_COUNTRY } from "./country.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Weekly", "has-action": false, "has-active": false, "has-filter-inactive": false, "search-by": "ItemCode", "ph-search-by": "Search by Item Code" }, scopedSlots: _vm._u([{ key: "customFilter", fn: function() {
    return [_c("div", { staticClass: "weekly-filter d-flex align-items-center" }, [_c("span", { staticClass: "mr-2 mb-0" }, [_vm._v(" Country ")]), _c("select-input", { staticClass: "select-filter", attrs: { "data-source": _vm.listCountry, "source": "code", "source-label": "countryName", "default-active-first-option": "" }, on: { "change": _vm.onCountryChange } })], 1)];
  }, proxy: true }]) }, [_c("text-field", { key: "Data", attrs: { "data-index": "data", "title": "Data", "sorter": true, "width": 70 } }), _c("text-field", { key: "FactoryName", attrs: { "data-index": "factoryName", "title": "Factory", "sorter": true, "width": 100 } }), _c("text-field", { key: "ItemCode", attrs: { "data-index": "itemCode", "title": "Item", "sorter": true, "width": 70 } }), _c("text-field", { key: "ItemDescription", attrs: { "data-index": "itemDescription", "title": "Description", "sorter": true, "width": 200 } }), _c("text-field", { key: "GroupTech", attrs: { "data-index": "groupTech", "title": "Group Tech", "sorter": true, "width": 120 } }), _c("text-field", { key: "Res", attrs: { "data-index": "res", "title": "RES", "sorter": true, "width": 70 } }), _c("text-field", { key: "FinWeek", attrs: { "data-index": "finWeek", "title": "PLNWK", "sorter": true, "parse": _vm.parseDate } }), _c("text-field", { key: "FinDay", attrs: { "data-index": "finDay", "title": "PLNDAY", "sorter": true, "parse": _vm.parseDate } }), _c("text-field", { key: "FinTime", attrs: { "data-index": "finTime", "title": "PLNTM", "sorter": true, "parse": _vm.parseTime, "width": 100 } }), _c("text-field", { key: "Quantity", attrs: { "data-index": "quantity", "title": "Plan", "sorter": true, "width": 100 } }), _c("text-field", { key: "FrozenPlan", attrs: { "data-index": "frozenPlan", "title": "PROD", "sorter": true, "width": 100 } }), _c("text-field", { key: "AbsVariance", attrs: { "data-index": "absVariance", "title": "ABSDF", "sorter": true, "width": 100 } })], 1);
};
var staticRenderFns$1 = [];
var Weekly_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".weekly-filter .select-filter[data-v-721c22da]{width:100px}\n")();
const __vue2_script$1 = {
  name: "Weekly",
  inject: ["crud", "facility"],
  data() {
    return {
      listCountry: LIST_COUNTRY
    };
  },
  async created() {
    await this.crud.setQueryString("facility", this.facility.value);
  },
  async beforeDestroy() {
    await this.crud.deleteQueryStrings();
  },
  methods: {
    parseDate(value) {
      return this.$moment(value).format("DD/MM/YYYY");
    },
    parseTime(value) {
      return value.slice(0, 5);
    },
    async onCountryChange(value) {
      await this.crud.setQueryString("countryCode", value);
      this.crud.fetchList();
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "721c22da", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var Weekly = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.menuItems } }), _c("resource", { attrs: { "name": "line-performance.ctps.weekly", "api-url": _vm.apiUrl } }, [_c("weekly")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { Weekly },
  data() {
    return {
      apiUrl,
      menuItems: [
        {
          key: "ctp",
          title: "CTP",
          path: ""
        },
        {
          key: "central-database",
          title: "Central Database",
          path: ""
        },
        {
          key: "weekly",
          title: "Weekly",
          path: "/line-performance/weekly"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
